import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store/store";
import { Message } from "element-ui";
import preloader from "@/util/preloader";
import { getCookie, cookies } from "@/util/cookies";
import axios from "@/axios";

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  preloader.fadeIn();

  console.log(to.path);

  // Check if the user is trying to access a route that requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isUserLoggedIn) {
      // Fetch user info that if not already fetched
      const isUserInfoFetched = store.getters.getIsUserInfoFetched;

      try {
        const response = await axios.get("/user");
      } catch (err) {
        console.log(err);

        if (err.response.status === 401) {
          await store.dispatch("logout");
          next({ path: "/login" });
        }
      }

      if (!isUserInfoFetched) {
        const workspace = getCookie(cookies.COOKIE_WORKSPACE);
        if (workspace) {
          store.commit("setSelectedWorkspace", workspace);
        }

        await Promise.all([store.dispatch("fetchUserInfo")]).catch((err) => {
          console.log("Error", err);
          Message({
            message: "Server error",
            type: "error",
          });
          throw new Error(); // TODO temporary => replace with error page
        });
      }

      /*  if (to.path === "/login") {
        console.log("ROUTE GUARD: User is logged in, redirect to the dashboard page");
        next({ path: "/" });
      } else { */
      // User is logged in, check if workspace is required
      if (shouldRedirectToWorkspace(to)) {
        console.log(
          `ROUTE GUARD: Workspace not selecte, redirect to route /workspace/login`,
        );
        next({ name: "workspace.login" });
      }
      // User is logged in, proceed to the requested route
      console.log(
        `ROUTE GUARD: User is logged in, proceed to the route ${to.path}`,
      );
      next();
      //}
    } else {
      // User is not logged in, redirect to the login page
      console.log(
        "ROUTE GUARD: User is not logged in, redirect to the login page",
      );
      next({ path: "/login" });
    }
  } else {
    if (to.path === "/login" && store.getters.isUserLoggedIn) {
      console.log(
        "ROUTE GUARD: User is logged in, redirect to the dashboard page",
      );
      next({ path: "/" });
    }
    // Route does not require authentication, proceed as usual
    console.log(
      "ROUTE GUARD: Route does not require authentication, proceed as usual",
    );
    next();
  }
});

router.afterEach((to, from, next) => {
  preloader.fadeOut();
});

export default router;

function shouldRedirectToWorkspace({ path }) {
  const hasWorkspace = getCookie(cookies.COOKIE_WORKSPACE);
  const hasWorkspacePlan = store.getters.getHasWorkspace;
  const isGoingToWorkspaceRoute = path.includes("workspace");

  return !hasWorkspace && !isGoingToWorkspaceRoute && hasWorkspacePlan;
}
