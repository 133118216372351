<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button />
      </div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <router-link class="navbar-brand logos-wrapper" :class="[
        userHasCompanyLogo ? 'custom-logo-dashboard' : 'logo-dashboard',
      ]" to="/">
        <img src="/img/brand/contents-white.svg?ts=202407121730" alt="" class="main-logo"
          v-show="!userHasCompanyLogo" />
        <img class="company-logo" v-show="userHasCompanyLogo" :src="user.extra_info?.company_logo" alt="" />
      </router-link>
      <div class="workspaceName">
        {{ workspaceName }} {{ $t('workspace.workspace') }}
      </div>

    </div>

    <ul class="navbar-nav d-flex navbar-align-left" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group searchbar_dashboard ml-2">
        <el-select :popper-append-to-body="false" class="select-primary w-100 navbar_placeholer searchBar_products"
          filterable size="medium" placeholder="Search tool here..." v-model="selectedTool">
          <el-option v-for="tool in tools" :key="tool.code" class="select-primary breadcrumb-link" :value="tool.slug"
            :label="tool.name">
          </el-option>
        </el-select>
      </div>

      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link"
        menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="photo">
            <img :src="avatar" />
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p @click="logout" class="d-lg-none navbar-log-out">Log out</p>
        </template>
        <li class="nav-link">
          <div class="username-wrapper">
            <div class="photo">
              <img :src="avatar" />
            </div>
            <div class="username-text-wrapper">
              <p class="username-name">{{ user?.name ?? "User" }}</p>
              <p class="username-email">{{ user?.email ?? "User" }}</p>
            </div>
          </div>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <router-link to="/my-profile" class="nav-item dropdown-item nav-option-list">My Profile
          </router-link>
        </li>
        <li v-if="userRole != 'user' || user.extra_info.force_control_panel" class="nav-link">
          <router-link to="/control-panel" class="nav-item dropdown-item nav-option-list">Control Panel
          </router-link>
        </li>
        <li class="nav-link">
          <router-link to="/user/api" class="nav-item dropdown-item nav-option-list">API
          </router-link>
        </li>
        <li class="nav-link">
          <div class="dark-mode-switch">
            <p class="mb-0 dark-mode-switch-text">{{ $t("dashboard.dark_mode") }}</p>
            <el-switch v-model="darkMode" @change="toggleMode"></el-switch>
          </div>
        </li>
        <div class="dropdown-divider"></div>
        <li v-if="isUserImpersonated" class="nav-link">
          <a href="/logout" @click.prevent="leaveImpersonate" class="nav-item dropdown-item nav-option-list">Leave
            Impersonation</a>
        </li>
        <li v-else class="nav-link">
          <a href="/logout" @click.prevent="logout" class="nav-item dropdown-item nav-option-list">Log out</a>
        </li>
      </base-dropdown>
      <!-- <li class="adjustments-line d-flex align-items-center">
        <div class="togglebutton switch-change-color mt-0">
          <el-switch v-model="darkMode" @change="toggleMode"> </el-switch>
        </div>
        <a @click.prevent="toggleFullScreen()">
          <i class="ml-4 fa-regular fa-expand expand_button"></i>
        </a>
      </li> -->
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";
import { Select, Option, Switch } from "element-ui";
import { getDarkMode, setDarkMode } from "../../services/localService";
import { mapGetters } from "vuex";

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      activeNotifications: false,
      faqUrl: this.$t("faqs.faqContacts"),
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      darkMode: getDarkMode(),
      workspaceOptions: [
        { value: "personal", label: "Personal" },
        { value: "business", label: "Business" },
      ],
      languageSelected: "EN",
      languageOptions: [
        { value: "EN" },
        { value: "IT" },
        { value: "DE" },
        { value: "ES" },
        { value: "FR" },
        { value: "BR" },
      ],
      searchTool: "",
      selectedTool: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      avatar: "getAvatar",
      userRole: "getUserRole",
      hasWorkspace: "getHasWorkspace",
      inPersonalWorkspace: "inPersonalWorkspace",
      isUserImpersonated: "getIsUserImpersonated",
      selectedWorkspace: "selectedWorkspace",
    }),
    workspaceName() {
      return this.selectedWorkspace?.name || this.$t("workspace.personal");
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    tools() {
      let ts = this.$store.getters.getTools;
      // hide taskAssignment tool in personal ws
      if (this.$store.getters.selectedWorkspace.id == null) {
        ts = ts.filter((tool) => !tool.has_task_assignment);
      }
      return ts.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    workspaceSelected() {
      return this.$store.getters.selectedWorkspace;
    },

    userHasCompanyLogo() {
      return this.user.extra_info?.company_logo ? true : false;
    },
  },
  methods: {
    toggleSidebar() {
      let bodyElementClasses = document.body.classList;
      let htmlElementClasses = document.documentElement.classList;
      if (!this.$sidebar.showSidebar) {
        bodyElementClasses.add("overflow-no-scroll");
        htmlElementClasses.add("overflow-no-scroll");
      } else {
        bodyElementClasses.remove("overflow-no-scroll");
        htmlElementClasses.remove("overflow-no-scroll");
      }
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleMode(type) {
      setDarkMode(type);
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },
    logout() {
      window.gtm.logout(this.user ?? []);
      this.$store.dispatch("logout");
    },
    leaveImpersonate() {
      this.$store.dispatch("leaveImpersonate");
    },
  },
  watch: {
    selectedTool() {
      if (this.selectedTool !== "") {
        const isCurrentToolPage = this.selectedTool !== this.$route.params.slug;
        const path = `/tools/${this.selectedTool}`;
        this.selectedTool = "";
        if (isCurrentToolPage) {
          this.$router.push(path);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.top-navbar {
  top: 0px;
}

.el-select-dropdown__list {
  padding: 4px 0 10px;
}

.main-logo,
.company-logo {
  max-height: 42px;
}

.logos-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 205px;
}

.expand_button {
  cursor: pointer;
}

.username-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .username-text-wrapper {
    font-size: 0.7500000025rem;
    padding: 0 10px;

    p {
      margin-bottom: 0;
    }

    .username-name {
      font-weight: bold;
    }

    .username-email {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.workspaceName {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--grey-566-a-7-f, #566A7F);
  background: #DEF;
  border-radius: 88px;
  font-size: 16px;
  font-weight: 800;
  width: 190px;
  height: 56px;
  padding: 0px 16px;
  gap: 8px;
  margin-left: -55px;
}
</style>
