import { Message } from "element-ui";

export { apiHandler, apiCreateOrderHandler };

const apiHandler = async (fn) => {
  try {
    const response = await fn();

    if (response?.data?.status === "success") {
      Message({
        showClose: true,
        message: response?.data?.message ?? response?.data?.msg ?? "Success",
        type: "success",
      });
      return response;
    }
    return null
  } catch (error) {
    console.log("Error", error);
    Message({
      showClose: true,
      message: error?.response?.data?.message ?? error?.response?.data?.msg ?? error?.message ?? "Generic error",
      type: "error",
    });
    return null;
  }
};

const apiCreateOrderHandler = async (fn, params) => {
  console.log('apiCreateOrderHandler')
  console.log(params)
  try {
    const response = await fn();

    if (response?.data?.status === "success") {
      return response;
    }

    return null;
  } catch (error) {
    console.log("Error", error);
    Message({
      showClose: true,
      message: error?.response?.data?.message ?? error?.response?.data?.msg ?? error?.message ?? "Generic error",
      type: "error",
    });

    let serviceName=''
    let serviceGroup=''
    if(params?.gtag_service_name){
      serviceName = params?.gtag_service_name
    } else if(params?.product_keys.gtag_service_name){
      serviceName = params?.product_keys.gtag_service_name
    }
    if(params?.gtag_service_group){
      serviceGroup = params?.gtag_service_group
    } else if(params?.product_keys.gtag_service_group){
      serviceGroup = params?.product_keys.gtag_service_group
    }

    window.gtm.tool_error(
      serviceName,
      serviceGroup,
      "Response is not success");
      return
  }
};
