<script src="../store/modules/linkBuilderGeneric.js"></script>
<template>
  <div>
    <div class="mb-3" v-if="!compact">
      <div v-if="!hideDragDrop" class="fileInput text-center" @click="triggerFileInput">
        <!-- <div
        class="fileInput-new thumbnail"
      >
      </div> -->
        <!-- <div> -->
        <!-- <span class="fileInput-new">{{
            fileExists ? changeText : selectText
          }}</span> -->
        <div class="fileInput__text-wrapper">
          <i class="fileInput__icon-size fa-solid fa-cloud-arrow-up"></i>
          <span>Drag & drop or <label>click to choose file</label></span>
          <span>Maximum size: 10 MB</span>
          <span>Supported format: {{ fileType }} </span>
        </div>
        <input type="hidden" value="" name="" />
        <input :accept="fileType" type="file" name="..." class="fileInput__input" :multiple="multiple"
          aria-invalid="false" @change="onFileChange" ref="fileInput" style="display: none" />
        <!-- </div> -->
      </div>

      <div v-if="fileExists">
        <div v-if="!multiple" class="fileInput__list-item">
          <i class="fa-solid fa-file"></i>
          <div class="fileInput__list-item--text-wrapper">
            <p v-show="fileList">{{ fileList?.name }}</p>
            <span v-show="fileList.size">{{ formatSize(fileList.size) }}</span>
          </div>
          <i @click="deleteFile" class="fa-solid fa-trash-xmark"></i>
        </div>
        <div v-else>
          <div v-for="(file, index) in fileList" :key="index" class="fileInput__list-item">
            <i class="fa-solid fa-file"></i>
            <div class="fileInput__list-item--text-wrapper">
              <p v-show="file">{{ file?.name }}</p>
              <span v-show="file">{{ formatSize(file.size) }}</span>
            </div>
            <i @click="deleteFile(index)" class="fa-solid fa-trash-xmark"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 fileInput__compact" v-if="compact" @click="triggerFileInput">
      <i class="fileInput__icon-size fa-solid fa-paperclip"></i>
      <span>Attachment</span>
      <input :accept="fileType" type="file" name="..." class="fileInput__input" :multiple="multiple"
        aria-invalid="false" @change="onFileChange" ref="fileInput" style="display: none" />
    </div>

    <div v-if="compact && fileExists">
      <div v-for="(file, index) in fileList" :key="index" class="feedbackUpload">
        <div class="feedbackUpload__wrapper">
          <p class="feedbackUpload__fileName">{{ file?.name }}</p>
          <span class="feedbackUpload__size">{{ formatSize(file.size) }}</span>
        </div>
        <i @click="deleteFile(index)" class="fa-solid fa-circle-xmark feedbackUpload__delete"></i>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "FileUpload",
  props: {
    // selectText: {
    //   type: String,
    //   default: 'Select image'
    // },
    // changeText: {
    //   type: String,
    //   default: 'Change'
    // },
    // removeText: {
    //   type: String,
    //   default: 'Remove'
    // }
    multiple: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
    },
    hideDragDrop: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      fileList: null,
      formattedFileSize: null,
    };
  },
  computed: {
    fileExists() {
      return this.fileList !== null && this.fileList.length > 0;
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    removeFile() {
      this.imagePreview = null;
      this.$emit("change", null);
    },
    onFileChange(file) {
      if (this.multiple) {
        this.fileList = [];
        for(let i = 0; i < file.target.files.length; i++) {
          this.fileList.push(file.target.files[i]);
        }
        this.$emit("addFile", this.fileList);
      } else {
        this.fileList = [event.target.files[0]];
        this.$emit("addFile", file.target.files[0]);
      }
    },

    deleteFile(index = null) {
      if(index !== null) {
        this.fileList.splice(index, 1);
        this.$emit("addFile", this.fileList);
      } else {
        this.fileList = null;
        this.$emit("deleteFile", this.fileList);
      }
    },

    formatSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
  },
};
</script>
<style scoped>
.fileInput__compact {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 120px;

  span {
    color: var(--blu-007-bff, #007BFF);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.fileInput__compact .fileInput__icon-size {
  margin-right: 5px;
  color: var(--blu-007-bff, #007BFF);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feedbackUpload {
  display: flex;
  width: 200px;
  padding: var(--Corner-radius, 6px) 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--Corner-radius, 6px);
  border: 1px solid var(--grey-d-8-d-8-d-8, #D8D8D8);
  background: var(--Base, #FFF);
  margin-bottom: 10px;
}

.feedbackUpload__delete {
  color: var(--grey-cccccc, #CCC);
  font-size: 12px;
  font-weight: 900;
  margin: 0 5px 2px 0;
  cursor: pointer;
}

.feedbackUpload__size {
  color: var(--grey-999999, #999);
  font-size: 12px;
  font-weight: 400;
}

.feedbackUpload__fileName {
  color: var(--grey-566-a-7-f, #566A7F);
  font-size: 12px;
  font-weight: 700;
}

.feedbackUpload__wrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--Corner-radius, 6px);
}
</style>