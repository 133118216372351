import axios from "@/axios";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    keywordsToSearch: "",
    brandVoiceSelected: null,
    searchLinkedKeywordsOnly: false,
    keywordsFound: [],
    keywordSearched: null,
    totalLinkedKeywordsFound: 0,
    totalSoftKeywordsFound: 0,
    loading: false,
    taskAssignmentOrdersCount: 0,
  };
};

const moduleCategoryCardsKeywords = {
  state: getDefaultState(),
  getters: {
    getKeywordsToSearch(state) {
      return state.keywordsToSearch;
    },
    getBrandVoiceSelected(state) {
      return state.brandVoiceSelected;
    },
    getSearchLinkedKeywordsOnly(state) {
      return state.searchLinkedKeywordsOnly;
    },
    getKeywordsFound(state) {
      return state.keywordsFound;
    },
    getKeywordSearched(state) {
      return state.keywordSearched;
    },
    getTotalLinkedKeywordsFound(state) {
      return state.totalLinkedKeywordsFound;
    },
    getTotalSoftKeywordsFound(state) {
      return state.totalSoftKeywordsFound;
    },
    getCategoryCardsKeywordsLoading(state) {
      return state.loading;
    },
    getTaskAssignmentOrdersCount(state) {
      return state.taskAssignmentOrdersCount;
    },
  },
  mutations: {
    setKeywordsToSearch(state, newKeywordsToSearch) {
      state.keywordsToSearch = newKeywordsToSearch.trim();
    },
    clearKeywordsToSearch(state) {
      state.keywordsToSearch = "";
    },
    setBrandVoiceSelected(state, newBrandVoiceSelected) {
      if (newBrandVoiceSelected.length === 0) {
        newBrandVoiceSelected = null;
      }
      state.brandVoiceSelected = newBrandVoiceSelected;
    },
    clearBrandVoiceSelected(state) {
      state.brandVoiceSelected = null;
    },
    setSearchLinkedKeywordsOnly(state, newSearchLinkedKeywordsOnly) {
      state.searchLinkedKeywordsOnly = newSearchLinkedKeywordsOnly;
    },
    setKeywordsFound(state, newKeywordsFound) {
      state.keywordsFound = newKeywordsFound;
    },
    clearKeywordsFound(state) {
      state.keywordsFound = [];
    },
    updateKeywordSearched(state) {
      state.keywordSearched = state.keywordsToSearch;
    },
    setTotalLinkedKeywordsFound(state, totalLinkedKeywordsFound) {
      state.totalLinkedKeywordsFound = totalLinkedKeywordsFound;
    },
    setTotalSoftKeywordsFound(state, totalSoftKeywordsFound) {
      state.totalSoftKeywordsFound = totalSoftKeywordsFound;
    },
    setCategoryCardsKeywordsLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setTaskAssignmentOrdersCount(state, count) {
      state.taskAssignmentOrdersCount = count;
    },
    orderKeywordsFound(state, order) {
      if (state.keywordsFound.length === 0) {
        return;
      }
      const { prop = "all_keywords", dir = "asc" } = order || {};

      switch (prop) {
        case "all_keywords":
          // used as default order. It sorts by linked_keywords and secondly by soft_keywords
          return state.keywordsFound.sort((a, b) => {
            const a_LinkedKeywordsLength = a.occurrences.linked_keywords.length;
            const b_LinkedKeywordsLength = b.occurrences.linked_keywords.length;
            if (a_LinkedKeywordsLength < b_LinkedKeywordsLength) return 1;
            if (a_LinkedKeywordsLength > b_LinkedKeywordsLength) return -1;

            // if linked_keywords are the same amount, don't return 0 (which would keep the elements at the original position) but sort by soft_keywords' amount
            const a_SoftKeywordsLength = a.occurrences.soft_keywords.length;
            const b_SoftKeywordsLength = b.occurrences.soft_keywords.length;
            if (a_SoftKeywordsLength < b_SoftKeywordsLength) return 1;
            if (a_SoftKeywordsLength > b_SoftKeywordsLength) return -1;
            return 0;
          });

        case "linked_keywords":
        case "soft_keywords":
          return state.keywordsFound.sort((a, b) => {
            const aKeywordsLength = a.occurrences[prop].length;
            const bKeywordsLength = b.occurrences[prop].length;
            if (aKeywordsLength < bKeywordsLength)
              return dir === "asc" ? -1 : 1;
            if (aKeywordsLength > bKeywordsLength)
              return dir === "asc" ? 1 : -1;
            return 0;
          });

        default:
          return state.keywordsFound.sort((a, b) => {
            if (a[prop] < b[prop]) return dir === "asc" ? -1 : 1;
            if (a[prop] > b[prop]) return dir === "asc" ? 1 : -1;
            return 0;
          });
      }
    },
  },
  actions: {
    async fetchCategoryCardsKeywords({ getters, commit }) {
      if (!getters.getKeywordsToSearch) {
        console.warn("Please provide a keyword to search");
        return;
      }
      commit("setCategoryCardsKeywordsLoading", true);
      return await apiHandler(async () => {
        const user = getters.getUser;
        if (!user) {
          throw new Error("User not authenticated");
        }
        const queryParams = {
          user_id: user.id,
          keyword_to_search: getters.getKeywordsToSearch,
          search_liked_keywords_only: getters.getSearchLinkedKeywordsOnly,
          brand_voice_id: getters.getBrandVoiceSelected,
          workspace_id: getters.selectedWorkspace.id,
          // ...params,
        };

        let { data } = await axios
          .post("tools/category_cards/find_keywords", queryParams)
          .catch((error) => {
            console.error("Cannot get keywords count", error);
            Message({
              showClose: true,
              message: error.response?.data?.message,
              type: "error",
            });
            commit("setCategoryCardsKeywordsLoading", false);
          });

        commit("setTaskAssignmentOrdersCount", data.count);
        commit("setKeywordsFound", data.results);
        commit("updateKeywordSearched");
        commit("orderKeywordsFound");
        // count keywords
        let linkedKeywordsCount = 0;
        let softKeywordsCount = 0;
        data.results
          .map((occurrance) => occurrance.occurrences)
          .forEach((occurrance) => {
            linkedKeywordsCount += occurrance.linked_keywords.length;
            softKeywordsCount += occurrance.soft_keywords.length;
          });
        commit("setTotalLinkedKeywordsFound", linkedKeywordsCount);
        commit("setTotalSoftKeywordsFound", softKeywordsCount);
        commit("setCategoryCardsKeywordsLoading", false);
      });
    },
  },
};

export default moduleCategoryCardsKeywords;
