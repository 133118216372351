import { render, staticRenderFns } from "./DashboardCompanyNavbar.vue?vue&type=template&id=6d98c313&scoped=true"
import script from "./DashboardCompanyNavbar.vue?vue&type=script&lang=js"
export * from "./DashboardCompanyNavbar.vue?vue&type=script&lang=js"
import style0 from "./DashboardCompanyNavbar.vue?vue&type=style&index=0&id=6d98c313&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d98c313",
  null
  
)

export default component.exports