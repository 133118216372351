import axios from "@/axios";
import { apiHandler } from "@/util/errorHandling";

const getDefaultState = () => {
  return {
    task: null,
    taskHistory: [],
    tasks: [],
    totalTasksNum: 0,
    form: {}
  };
};

const moduleTaskAssignment = {
  state: getDefaultState(),
  getters: {
    getTask(state) {
      return state.task;
    },
    getTaskHistory(state) {
      return state.taskHistory;
    },
    getTasks(state) {
      return state.tasks;
    },
    getTotalTasksNum(state) {
      return state.totalTasksNum;
    },
    getForm(state) {
      return state.form;
    }
  },
  mutations: {
    clearModuleTasks(state) {
      Object.assign(state, getDefaultState());
    },
    setTask(state, task) {
      state.task = task;
    },
    setTaskHistory(state, taskHistory) {
      state.taskHistory = taskHistory;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setTotalTasksNum(state, totalTasksNum) {
      state.totalTasksNum = totalTasksNum;
    },
    setForm(state, form) {
      state.form = { ...form };
    }
  },
  actions: {
    async fetchTask(context, order_detail_id) {
      if (order_detail_id) {
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id
          };

          let response = await axios.get("tools/task_assignments/" + order_detail_id, {
            params: queryParams,
          });

          const task = response.data.taskAssignment;
          context.commit("setTask", task);
          context.dispatch("fetchTaskHistory", { task_id: task.id });
          return;
        }
      }

      throw new Error("Failed to fetch task");
    },

    async fetchTasks({ getters, commit }, params = {}) {
      const user = getters.getUser;
      if (user) {
        const queryParams = {
          user_id: user.id,
          workspace_id: getters.selectedWorkspace?.id,
          ...params,
        };

        let response = await axios.get("tools/task_assignments", {
          params: queryParams,
        });

        if (response) {
          const totalTasksNum = response.data.recordsTotal;
          const tasks = response.data.taskAssignments;

          commit("setTasks", tasks);
          commit("setTotalTasksNum", totalTasksNum);
          return;
        }
      }

      throw new Error("Failed to fetch tasks");
    },

    async updateTask({ getters, commit, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.patch("tools/task_assignments/" + params.order_detail_id, params);
        commit("setTask", response.data.taskAssignment);
        dispatch("fetchTaskHistory", { task_id: response.data.taskAssignment.id });

        return response;
      });
    },

    async fetchTaskHistory(context, params) {
      if (params.task_id) {
        // Get the currently authenticated user
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id,
            ...params,
          };

          // Fetch tasks based on the specified task type
          let response;
          response = await axios.get("tools/task_assignments/history/" + params.task_id, {
            params: queryParams,
          });

          const taskHistory = response.data.taskHistory;

          // If orders are retrieved, process and store the first order
          context.commit("setTaskHistory", taskHistory);
          return;

        }
      }

      // Throw an error if the required parameters are not provided or if the task retrieval process fails
      throw new Error("Failed to fetch task");
    },

    async createTaskMetaData({ getters, commit }, form) {
      try {
        const user = getters.getUser;
        if (!user) throw new Error("User not authenticated");

        const data = {
          user_id: user.id,
          footer_content: form.footer_content,
          brand: form.brand,
          order_detail_id: form.order_detail_id,
          category_name: form.category_name,
          order_det_id: form.order_detail_id,
          primary_kw: form.primary_kw,
        };

        const response = await axios.put("tools/task_assignments/metadata/", data);

        commit("setForm", {
          ...form,
        });

        return response.data;

      } catch (error) {
        console.error("Failed to create task metadata:", error);
        throw new Error("Failed to create task metadata");
      }
    },

    async optimizeTaskMetaData({ getters, commit }, form) {
      try {
        const user = getters.getUser;
        if (!user) throw new Error("User not authenticated");

        const data = {
          user_id: user.id,
          footer_content: form.footer_content,
          brand: form.brand,
          order_detail_id: form.order_detail_id,
          category_name: form.category_name,
          order_det_id: form.order_detail_id,
          primary_kw: form.primary_kw,
          meta_title_description: form.meta_title_description,
        };

        const response = await axios.put("tools/task_assignments/metadata/", data);
        const order = response.data;

        commit("setForm", {
          ...form,
          meta_title_description: {
            meta_title: order.taskMetaData.api_output.optimized_meta_title_description.meta_title,
            meta_description: order.taskMetaData.api_output.optimized_meta_title_description.meta_description,
          }
        });

        return response.data;

      } catch (error) {
        console.error("Failed to optimize task metadata:", error);
        throw new Error("Failed to optimize task metadata");
      }
    },

    async saveTaskMetaData({ getters }, form) {
      try {
        const user = getters.getUser;
        if (!user) throw new Error("User not authenticated");

        const data = {
          user_id: user.id,
          footer_content: form.footer_content,
          brand: form.brand,
          order_detail_id: form.order_detail_id,
          category_name: form.category_name,
          primary_kw: form.primary_kw,
          meta_title_description: form.meta_title_description,
          switchInput: form.switchInput
        };

        const response = await axios.put("tools/task_assignments/save-metadata/", data);

        return response.data;
      } catch (error) {
        console.error("Failed to save task metadata:", error);
        throw new Error("Failed to save task metadata");
      }
    },
    async toggleMetaData({ getters }, value) {
      try {
        const response = await axios.put("tools/task_assignments/toggle-metadata/", value);
        return response.data;
      } catch (error) {
        console.error("Failed to save toggle metadata:", error);
        throw new Error("Failed to save toggle metadata");
      }
    },
    async updatePageUrlTaskAssignment({ getters }, data) {
      try {
        const response = await axios.put("tools/task_assignments/update-pageurl/", data);
        const task = response.data.taskAssignment;
        context.commit("setTask", task);
      } catch (error) {
        console.error("Failed to update page_url:", error);
        throw new Error("Failed to update page_url");
      }
    }
  }
};

export default moduleTaskAssignment;
